<template>
  <div id="dragonDiv" class="" :class="activeGifClass" v-if="guardedByDragon">
    <img :src="'/images/'+activeGif"  class="dragongif">
  </div>

  <top-nav :pageMode="pageMode"></top-nav>

  <div class="container" style="margin-top:170px;">
    <div style="display:flex;">
      <h5 style="margin:auto;">{{this.balanceTitle}}</h5>
    </div>
    <hr>

    <div class="row" v-if="balanceDataLoaded">
      <div class="">
        <ul class="list2">
          <li class="user listitem clickable" v-for="(user) in familyUsers" :key="user.ID" :class="{active:this.activeForecastUserId===user.ID, centralBank:user.CENTRAL_BANK==='1'}">
            <div class="d-flex flex maindiv" @click="ToggleForecastAccountActive(user.ID)">
              <div class="d-flex flex userDiv">
                <div class='avatar'><img :src="getAvatar(user)" alt="avatar"></div>
                <div class="info">
                  <font-awesome-icon v-if="user.EXPIRE_STATUS === '1'" class="expiring" :icon="['fas', 'exclamation-triangle']"/>
                  <font-awesome-icon  v-if="user.EXPIRE_STATUS === '4'" class="expired" :icon="['fas', 'exclamation-circle']"/>
                  <div>{{user.FULL_NAME}}</div>
                </div>
              </div>
              <div class="balance">
                <div class="variance" :class="numberColor2(this.varianceUsers[user.ID])">{{getVariance(this.varianceUsers[user.ID])}}</div>
                <div class="upDownArrowDiv"><font-awesome-icon class="upDownArrow" :icon="['fas', upDownArrow(this.balancesForecastedUsers[user.ID], this.varianceUsers[user.ID])]"/></div>
                <div>{{this.vueNumberFormat(this.balancesForecastedUsers[user.ID])}}</div>
              </div>
            </div>
            <div class="userBAs">
              <ul>
                <li class="userBA d-flex clickable" v-for="(userAccount) in getAccounts(user.ID)" :key="userAccount.BA_ID" @click="gotoNewTran(user.ID, userAccount.BA_ID)">
                  <div class="info">
                    <font-awesome-icon v-if="userAccount.EXPIRE_STATUS === '1'" class="expiring" :icon="['fas', 'exclamation-triangle']"/>
                    <font-awesome-icon  v-if="userAccount.EXPIRE_STATUS === '4'" class="expired" :icon="['fas', 'exclamation-circle']"/>
                    <div><font-awesome-icon class="accountIcon" :icon="['fas', 'university']"/> {{userAccount.ACCOUNT_NAME}}</div>
                  </div>
                  <div class="info">{{this.vueNumberFormat(userAccount.BALANCE)}}</div>
                </li>

              </ul>

              <div class="gotoBtnDiv clickable" @click="goToUserPage(user.ID)" v-if="isAdmin || user.ID === this.userInfo.ID"><div class="btn gotoBtn">Go to {{user.FULL_NAME}}'s Banking Center</div></div>
            </div>

          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="d-flex flex periodOptions">
        <div v-for="periodOption in periodOptions" :key="periodOption.id" class="periodOption clickable" :class="{active:periodOption.id===periodOptionActiveId}" @click="updateMonths(periodOption)">{{periodOption.name}}</div>
      </div>
    </div>


  </div>

  <div class="container">
    <div style="display:flex;">
      <h5 style="margin:auto;">Balance Chart (1 Year )</h5>
    </div>
    <hr>

    <div class="row">
      <BalanceChart :balanceChartData="this.balanceChartData"  v-if="balanceChartDataReady"/>
    </div>
  </div>



</template>

<script>
// @ is an alias to /src
import { ResizeObserver } from '@juggle/resize-observer';
import BalanceChart from "@/components/BalanceChart";
import {colors} from "@/services/colors";
import TopNav from "@/components/BankingCenter/TopNav";
import { datetime, RRule, RRuleSet, rruleDb } from 'rrule';

var loader;
var resSumByBas = [];
var resSumByUsers = [];



var fps = [
  {name:'1M', months:1},
  {name:'3M', months:3},
  {name:'6M', months:6},
  {name:'1Y', months:12},
  {name:'5Y', months:60},
];



export default {
  name: 'Home',
  components: {
    BalanceChart,
    TopNav
  },
  data(){
    return{
      pageMode:'accounts',

      isAdmin:false,

      balanceDataLoaded: false,
      chartDataLoaded: false,

      familyUsers:[],
      familyUsersMeOnly:[],
      userInfo:{},
      familyInfo:{},
      familyUsersNoC:[],
      accounts: [],
      accountsIntial: [],
      accountsNoCB: {},
      tranAccounts:{},
      tranAccountsNoB:{},
      tranAccountsNoCB:{},
      balancesActualUsers:{},
      //balancesForecastedUsers:{},
      activeActualUserId:null,
      activeForecastUserId:null,
      users:{},
      upcomingTrans:[],
      schedules:[],
      allUpcomingTrans:[],
      resSumByBas:[],
      resSumByUsers:[],
      futureBalances:{},
      balanceChartData:{},
      balanceChartDataReady: false,


      activeGif:'dragon2.gif',
      activeGifClass:'gifleft',
      gifs:['dragon1.gif', 'dragon2.gif', 'dragon3.gif'],
      gifDirections:['moveRight','static','moveLeft'],
      slides:[
        {name:'dragon1.gif', class:'left0to30'},
        {name:'dragon2.gif', class:'gifmid'},
        {name:'dragon1.gif', class:'left30to60'},
        {name:'dragon2.gif', class:'gifright'},
        {name:'dragon4.gif', class:'right0to30'},
        {name:'dragon2.gif', class:'gifmid'},
        {name:'dragon4.gif', class:'right30to60'},
        {name:'dragon2.gif', class:'gifleft'},
      ],

      periodOptions:[],
      periodOptionActiveId:0,
      periodOptionDescription:'',
      months:0,
      guardedByDragon:true,
      showFamilyCb:true,
      userGoalChartData:{}
    }
  },

  computed:{
    balanceTitle(){
      return this.months === 0 ? 'Current Balances' : 'Balances ' + this.periodOptionDescription
    },
    balancesForecastedUsers(){
      return this.accounts.reduce((a,b)=> (a[b.USER_ID] = (a[b.USER_ID] || 0) + parseFloat(b.BALANCE), a), {})
    },
    balanceInitialUsers(){
      return this.accountsIntial.reduce((a,b)=> (a[b.USER_ID] = (a[b.USER_ID] || 0) + parseFloat(b.BALANCE), a), {})
    },
    varianceUsers(){
      return this.accounts.reduce((a,b)=> (a[b.USER_ID] = this.balancesForecastedUsers[b.USER_ID] - this.balanceInitialUsers[b.USER_ID], a), {})
    }

  },


  methods:{



    loadData: async function(){
      let refreshed = await this.$root.doRefresh();

      this.userInfo  = this.$root.getLocalUserInfo() ?? {};
      this.familyInfo  = this.$root.getLocalFamilyInfo() ?? {};
      this.guardedByDragon = this.familyInfo.GUARDED_BY_DRAGON === '1';
      this.showFamilyCb = this.familyInfo.SHOW_FAMILY_CB === '1';

      this.isAdmin = this.userInfo.FAMILY_ADMIN === '1';

      var familyUsers = JSON.parse(localStorage.getItem('familyUsers')) ?? [];
      this.familyUsersNoC = familyUsers.filter(a => a.CENTRAL_BANK === '0');
      this.familyUsersMeOnly = familyUsers.filter(a => a.ID === this.userInfo.ID);

      this.familyUsers  = this.showFamilyCb ? familyUsers : this.familyUsersNoC;
      //this.familyUsers  = this.userInfo.VIEW_OTHERS === '0' ? this.familyUsersMeOnly : this.familyUsers;

      //console.log(this.familyUsers);

      this.periodOptions = this.$root.periodOptions;

      let schedules = this.$root.getLocalSchedules();
      this.accounts = this.$root.getLocalBas();
      this.accountsIntial = this.$root.getLocalBas();

      if(refreshed){
        var allUpcomingTrans = this.$root.getAllUpcomingTrans(schedules);
        this.futureBalances = this.$root.createFutureBalances(this.periodOptions, schedules, allUpcomingTrans);
        this.balanceChartData = this.$root.createBalanceChartData(schedules, allUpcomingTrans);
      }else{
        this.futureBalances = JSON.parse(localStorage.getItem('futureBalances'));
        this.balanceChartData = JSON.parse(localStorage.getItem('balanceChartData'));
      }

      this.balanceChartDataReady = true;
      this.balanceDataLoaded = true;
      loader.hide();

    },




    updateMonths(periodOption){
      this.$root.playClickSound();
      this.periodOptionActiveId = periodOption.id;
      this.months = periodOption.months;
      this.periodOptionDescription = periodOption.description;

      if(periodOption.name === 'Now'){
        this.accounts = JSON.parse(JSON.stringify(this.accountsIntial));
      }else{
        var futureBalancesBa = this.futureBalances[periodOption.name];
        //console.log(futureBalancesBa)

        this.accounts.forEach((ba) => {
          var baId = ba.BA_ID;
          var initalBalance = this.accountsIntial.filter(ba=>ba.BA_ID===baId)[0];
          //console.log(initalBalance)
          var futureBalance = futureBalancesBa.filter(fb=>fb.baId===baId)[0];
          if(futureBalance){
            //console.log(futureBalance)
            ba.BALANCE = parseFloat(initalBalance.BALANCE) + futureBalance.amount || 0;
          }
        });
      }


    },









    setPageMode(mode){
      this.pageMode = mode;
      if(mode === 'last30days' || mode === 'next30days'){
        this.getKidsTrans();
      }
    },

    gotoNewTran(userId, baId){
      if(this.isAdmin || this.userInfo.ID === userId){
        this.$root.playClickSound();
        this.$root.gotoRoute({ path: '/tran/new', query: { userId: userId, tranBaId: baId, io: 'in' }});
      }

    },

    gotoUserBudgetingBa:function(userId){
      const userBudgetingBa = this.accounts.filter(ba => ba.ACCOUNT_TYPE === "budgeting" && ba.USER_ID === userId)[0];
      console.log(userBudgetingBa);
      this.$root.gotoRoute({ path: '/budget/tran', query: { baId: userBudgetingBa.BA_ID } })
    },

    goToSavingGoal:function(userId){
        this.$root.gotoRoute({ path: '/settings/family/savinggoal', query: { userid: userId } })
    },

    goToUserPage(userId){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/bc/member', query: { userId: userId } })
    },

    getTranAccounts:function(userId){
      return this.tranAccountsNoB.filter(ba => ba.USER_ID === userId);
    },


    getAccounts:function(userId){
      return this.accounts.filter(ba => ba.USER_ID === userId);
    },

    ToggleForecastAccountActive:function(userId){
      this.$root.playClickSound();
      this.activeForecastUserId = this.activeForecastUserId === userId ? null : userId;
    },

    ToggleActualAccountActive:function(userId){
      this.$root.playClickSound();
      this.activeActualUserId = this.activeActualUserId === userId ? null : userId;
    },



    delay:function(ms){
      return new Promise(res => setTimeout(res, ms))
    },

    getActiveGif:async function(){

      for (var i = 0; i < this.slides.length; i++) {
        await this.delay(5000);
        let thisSlide = this.slides[i];

        this.activeGif = thisSlide.name;
        this.activeGifClass = thisSlide.class;
        //console.log(thisSlide.name);

        if(i===this.slides.length - 1){
          this.getActiveGif();
        }
      }

    },


    getAvatar:function(user){
      if(user.CENTRAL_BANK === '1'){
        return '/images/avatar/gb.png';
      }else{
        return user.AVATAR_URL;
      }
    },



    numberColor:function(num){
      return num < 0 ? 'negative' : '';
    },
    numberColor2:function(num){
      return num < 0 ? 'negative' : num === 0 ? 'hidden' : 'positive';
    },

    upDownArrow:function(budget,variance){
      if(variance > 0){
        return 'long-arrow-alt-up';
      }else if(variance < 0){
        return 'long-arrow-alt-down';
      }else{
        return 'dot-circle';
      }
    },

    getVariance(variance){
      //console.log(variance);
      if(variance === null) variance = 0;
      var sign = variance > 0 ? '+' : '';
      return variance === 0 ? '' : sign + this.vueNumberFormat(variance);
    },

    getLocalDateString:function(d){
      return d.toLocaleDateString();
    },





    sumByBas(d, allUpcomingTrans){
      var trans = allUpcomingTrans.filter(tran=>tran.TRAN_DATE <= d);
      resSumByBas = [];
      trans.reduce(function(res, tran) {
        if (!res[tran.ACCOUNT_ID]) {
          res[tran.ACCOUNT_ID] = {baId: tran.ACCOUNT_ID, userId:tran.USER_ID, amount: 0};
          resSumByBas.push(res[tran.ACCOUNT_ID]);
        }
        res[tran.ACCOUNT_ID].amount += parseFloat(tran.AMOUNT);
        return res;
      }, {});


      //console.log(trans);
      //console.log(resSumByBas);
    },

    sumByUsers(){
      resSumByUsers = [];
      resSumByBas.reduce(function(res, ba) {
        if (!res[ba.userId]) {
          res[ba.userId] = {userId: ba.userId, amount: 0};
          resSumByUsers.push(res[ba.userId])
        }
        res[ba.userId].amount += parseFloat(ba.amount);
        return res;
      }, {});


      console.log(resSumByUsers);
    },

    isLater(newDate, oldDate){
      return Date.parse(newDate) > Date.parse(oldDate);
    },

  },
  created() {
    this.$root.me = this.$root.getLocalUserInfo();
    //console.log(this.$root.me);

    /*const familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
    this.familyUsers = familyUsers.reduce((a,b)=> (a[b.ID] = b, a), {});
    console.log(this.familyUsers);*/






  },

  mounted() {
    document.title = "Accounts & Balances";

    loader = this.$loading.show();

    this.loadData();


    if(this.guardedByDragon){
      this.getActiveGif();
    }

  }
}
</script>

<style scoped>

  .logo{
    width:50px;
  }


  li {

  }

  .account{
    height: 70px;
    background-color: rgba(54, 162, 235, 0.8);
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3px 5px;
  }

  .accountIcon{
    color:#0dcaf0;
    margin-right:10px;
  }

  .account.f {
    background-color: rgba(255, 99, 132, 0.7);
  }

  .account.c{
    background-color: #ffd603;
    color:#000;
  }

  .user.centralBank{
    background-color: #ffd603;
    color:#000;
  }

  .account:hover{
    opacity: 0.8;
  }


  .periodOptions{
    width:100%;
    display:flex;
    justify-content: center;
    margin:10px 2px 20px 2px;
  }

  .periodOption.active{
    background-color: #3b3b3b;
    color:white;
  }

  .periodOptions div{
    font-size:12px;
    background-color: #f5f5f5;
    padding:5px 10px;
    margin:3px;
    border-radius:5px;
    width:50px;
    text-align: center;
  }





















  #dragonDiv{
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index:20;

  }

  #dragonDiv img{
    width:150px;
  }

  .left0to30{
    animation: left0to30 5s infinite;
  }

  @keyframes left0to30 {
    0% { left: 0;}
    100%{ left: calc(50% - 75px);}
  }



  .left30to60{
    animation: left30to60 5s infinite;
  }

  @keyframes left30to60 {
    0% { left: calc(50% - 75px);}
    100%{ left: calc(100% - 150px);}
  }

  .right0to30{
    animation: right0to30 5s infinite;
  }

  @keyframes right0to30 {
    0% { left: calc(100% - 150px);}
    100%{ left: calc(50% - 75px);}
  }

  .right30to60{
    animation: right30to60 5s infinite;
  }

  @keyframes right30to60 {
    0% { left: calc(50% - 75px);}
    100%{ left: 0;}
  }


  .gifmid{
    left:calc(50% - 75px);
  }


  .gifright{
    left:calc(100% - 150px);
  }

  .gifleft{
    left:0;
  }




  @media only screen and (max-width: 600px) {

    #dragonDiv img{
      width:100px;
    }

    .left0to30{
      animation: left0to30 5s infinite;
    }

    @keyframes left0to30 {
      0% { left: 0;}
      100%{ left: calc(50% - 50px);}
    }



    .left30to60{
      animation: left30to60 5s infinite;
    }

    @keyframes left30to60 {
      0% { left: calc(50% - 50px);}
      100%{ left: calc(100% - 100px);}
    }

    .right0to30{
      animation: right0to30 5s infinite;
    }

    @keyframes right0to30 {
      0% { left: calc(100% - 100px);}
      100%{ left: calc(50% - 50px);}
    }

    .right30to60{
      animation: right30to60 5s infinite;
    }

    @keyframes right30to60 {
      0% { left: calc(50% - 50px);}
      100%{ left: 0;}
    }


    .gifmid{
      left:calc(50% - 50px);
    }


    .gifright{
      left:calc(100% - 100px);
    }

    .gifleft{
      left:0;
    }
  }




  .list2 li{

    margin:3px 5px;
    font-size:14px;
    align-items: center;
    border:2px solid #fff;
    position:relative;
    background-color: #f5f5f5;
    border-radius:5px;
    padding:5px;
  }


  .avatar{
    margin-left:5px;
  }

  .list2 .avatar img{
    width:40px;
    height:40px;
    border:2px solid #9c9c9c;
    border-radius: 50%;
    padding:3px;
  }

  .list2 li.active{

  }



  .maindiv{
    justify-content: space-between;
    align-items: center;
  }

  .userDiv{
    align-items: center;
  }

  .avatar{
    margin:3px 10px 3px 3px;
  }

  .balance{
    margin-right:20px;
    display:flex;
    justify-content: left;
    align-items: center;
  }

  .list2 li .userBA{
    justify-content: space-between;
    font-size:12px !important;
    padding:5px 10px;
    background-color: #ffffff;
  }

  .list2 li .goalChart{
    font-size:12px !important;
    padding:5px 10px;
    background-color: #ffffff;
  }

  .userBAs{
    display:none;
  }

  li.active .userBAs{
    display:block;
  }

  .expired{
    color:red;
    margin-right:5px;
  }

  .expiring{
    color: #d2d21c;
    margin-right:5px;
  }

  .info{
    display:flex;
    align-items: center;
  }


  canvas{
    width:100% !important;
  }

  .tran{
    font-size:12px !important;
    justify-content: space-between;
  }

  .tranDate{
    margin-right:20px;
    margin-left:10px;
  }

  .upcomingTranTitle{
    justify-content: space-between;
  }

  .reviewTran{
    width:50px;
    height:20px;
    text-align: right;
  }

  .savingGoalLn{
    margin-top:20px !important;
  }

  .none{
    font-size:12px !important;
  }

  .fa-long-arrow-alt-up{
    color:green;
  }

  .fa-long-arrow-alt-down{
    color:red;
  }

  .fa-dot-circle{
    color:#f5f5f5;
    font-size:8px;
  }

  .upDownArrowDiv{
    display:inline-block;
    width:20px;
    margin-right:10px;
  }

  .upDownArrow{
    font-size:10px;
  }

  .variance{
    margin-right:5px;
    font-size:10px;
  }


  .userBA:hover{
    background-color: #0dcaf0 !important;
  }

  .userBA:hover .info{
    color:#ffffff !important;
  }

  .userBA:hover .accountIcon{
    color:#ffffff !important;
  }

  .fa-dot-circle{
    display:none;
  }

  .gotoBtnDiv{
    display:flex;
    justify-content: center;
    margin:10px;
  }

  .gotoBtn{
    background-color: #b7fa8b;
  }
</style>
