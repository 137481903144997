<template>
  <div id="chart">
    <LineChart :chartData="balanceChartData" :options="balanceChartData.options"/>
  </div>
</template>

<script>

import { ref, onMounted, defineComponent } from 'vue'
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import {colors} from "@/services/colors";
import axios from 'axios';
import config from '@/services/config';

Chart.register(...registerables);

export default{
  name: 'BalanceChart',
  components: { LineChart },
  props:{
    balanceChartData:{
      type:Object,
      required: true
    },
  },
  data(){
    return{
      chartData:{},
      options:{},
      loaded:false
    }

  },

  mounted(){

    //console.log(JSON.stringify(chartData.value));
    this.loaded = true;




  },





};
</script>


<style scoped>

#chart{
  margin-top:20px;

}

</style>
